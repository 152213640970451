import NavbarAdm from "../../components/Nav/Navbar"
import { ToolBar } from "../../components/ToolBar/ToolBar"
import "./myAutos.css";
import { IoCreateOutline, IoLocationOutline, IoEyeOutline, IoHeartOutline, IoLogoWhatsapp, IoCallOutline, IoHomeOutline, IoInfiniteOutline, IoCarOutline, IoTrashOutline} from 'react-icons/io5';
import { useFetch } from "../../hooks/useFetch";
import { DateFormat2 } from "../../components/DateFormat2/DateFormat2";
import { EditStatusAuto } from "../../components/EditStatusAuto/EditStatusAuto";
import { CountersViews } from "../../components/CountersProperties/CountersViews";
import { CountersFavorites } from "../../components/CountersProperties/CountersFavorites";
import { CountersContact } from "../../components/CountersProperties/CountersContact";
import { CountersWhatsapp } from "../../components/CountersProperties/CountersWhatsapp";
import { useContext, useEffect } from "react";
import { useState } from "react";
import api from "../../services/api";
import { DeleteAuto } from "../../components/DeleteAuto/DeleteAuto";
import { ViewAutoList } from "../../components/ViewAutoList/ViewAutoList";
import { MatchAuto } from "../../components/MatchAuto/MatchAuto";
import { MatchAutoSearch } from "../../components/MatchAutoSearch/MatchAutoSearch";
import { AuthContext } from "../../contexts/Auth";

export function MyAutos() {
    const Local = localStorage.getItem("adm-suachaveauto");
    const user = JSON.parse(Local);

    const { newAuto } = useContext(AuthContext);

    const [type, setType] = useState("");
    const [subType, setSubType] = useState("");
    const [availability, setAvailability] = useState("");
    const [status, setStatus] = useState("");
    const [emphasis, setEmphasis] = useState(false);
    
    const [filter, setFilter] = useState("");

    const [search, setSearch] = useState("");
    const searchLower = search.toLowerCase();
    const [plains, setPlains] = useState([]);
    const [data, setData] = useState([]);
    const [myIntegrationProperty, setMyIntegrationProperty] = useState([]);

    useEffect(() => {
        async function loadPaymet() {
            await api.get(`/myplain/${user.id}`).then((res) => {
                setPlains(res.data[0]);
            }).catch((err) => {
                console.error(err);
            });
        }

        loadPaymet()
    },[])


        useEffect(() => {
        async function loadProperty() {
            await api.get(`/autos/company/${user.id}`).then((res) => {
                setData(res.data);
            }).catch((err) => {
                console.error(err);
            });
        }

        loadProperty()
    },[])

    useEffect(() => {
        async function loadIntegrationsInfo() {
            await api.get(`/integrationsCompany/company/${user.id}`, data).then((res) => {
              loadIntegrations(
                  res.data[0].idCompany, res.data[0].nameCompany, res.data[0].avatarCompany, res.data[0].business, res.data[0].link, res.data[0].type, res.data[0].email, res.data[0].phone, res.data[0].whatsapp, 
              )
            }).catch((err) => {
                console.error(err)
            });
        }
  
        async function loadIntegrations(idCompany, nameCompany, avatarCompany, business, link, type, email, phone, whatsapp ) {
          const data = {idCompany, nameCompany, avatarCompany, business, link, type, email, phone, whatsapp }
            await api.post(`/integrations`,data ).then((res) => {
              setMyIntegrationProperty(res.data);
            }).catch((err) => {
                console.error(err)
            });
        }
  
        loadIntegrationsInfo()
    },[])

    
    if(!data) {
        return (
            <h5>Carregando...</h5>
        )
    }


    function handleEmphasis(e) {
        
        if(e.target.value === "true") {
            setEmphasis(true)
            setStatus("")
            setSubType("")
            setType("")
            setAvailability("")
            setSearch("")
        } else {
            setEmphasis(false)
            setStatus("")
            setSubType("")
            setType("")
            setAvailability("")
            setSearch("")
        }
        console.log(e.target.value)
    }
    function handleType(e) {
        setType(e.target.value)
        console.log(e.target.value)
    }
    function handleSubType(e) {
        setSubType(e.target.value)
        console.log(e.target.value)
    }

    function handleStatus(e) {
        setStatus(e.target.value)
        console.log(e.target.value)
    }
    function handleAvailability(e) {
        setAvailability(e.target.value)
        console.log(e.target.value)
    }
    function handleClear() {
        setStatus("")
        setSubType("")
        setType("")
        setAvailability("")
        setEmphasis(false)
    }

    function selectLists(data) {
        setFilter(data);
        console.log(data)
    }







    const autosSelecteds = filter === "New" ? myIntegrationProperty : filter === "Old" ? data : data
   
    
    const emphasisFilter = autosSelecteds?.filter((companies) => companies.emphasis === emphasis)
    const statusFilter = autosSelecteds?.filter((companies) => companies.status === status || companies.status === "Aluguel e Venda")
    const availabilityFilter = autosSelecteds?.filter((companies) => companies.availability === availability)
    const availabilityStatusFilter = autosSelecteds?.filter((companies) => companies.availability === availability && companies.status === status || companies.status === "Aluguel e Venda")
    const availabilityStatusFilterType = autosSelecteds?.filter((companies) => companies.availability === availability && companies.status === status || companies.status === "Aluguel e Venda" && companies.type === type)
    const availabilityStatusFilterTypeSubtype = autosSelecteds?.filter((companies) => companies.availability === availability && companies.status === status || companies.status === "Aluguel e Venda" && companies.type === type && companies.subType === subType)
    const statusFilterTypeSubtype = autosSelecteds?.filter((companies) =>  companies.status === status || companies.status === "Aluguel e Venda" && companies.type === type && companies.subType === subType)
    const availabilityFilterTypeSubtype = autosSelecteds?.filter((companies) => companies.availability === availability && companies.type === type && companies.subType === subType)
    const statusFilterType = autosSelecteds?.filter((companies) =>  companies.status === status || companies.status === "Aluguel e Venda" && companies.type === type )
    const availabilityFilterType = autosSelecteds?.filter((companies) => companies.availability === availability && companies.type === type )
    const typeFilter = autosSelecteds?.filter((companies) => companies.type === type)
    const subTypeFilter = autosSelecteds?.filter((companies) => companies.type === type && companies.subType === subType)
    const searchFilter = autosSelecteds?.filter((companies) => companies.brand?.toLowerCase().includes(searchLower)
                                    || companies.model?.toLowerCase().includes(searchLower)
                                    || companies.version?.toLowerCase().includes(searchLower)
                                    || companies.plate?.toLowerCase().includes(searchLower)
                                    || companies.year?.toLowerCase().includes(searchLower)
                                    || companies.yearModel?.toLowerCase().includes(searchLower)
                                    || companies.idIntegration?.toLowerCase().includes(searchLower)
                                    || companies.id?.toLowerCase().includes(searchLower))


    const filterData = search !== "" && status === "" && availability === "" && type === "" && subType === "" && emphasis === false ? searchFilter
                     : search === "" && status !== "" && availability === "" && type === "" && subType === "" && emphasis === false ? statusFilter 
                     : search === "" && status === "" && availability !== "" && type === "" && subType === "" && emphasis === false ? availabilityFilter 
                     : search === "" && status !== "" && availability !== "" && type === "" && subType === "" && emphasis === false ? availabilityStatusFilter 
                     : search === "" && status !== "" && availability !== "" && type !== "" && subType === "" && emphasis === false ? availabilityStatusFilterType 
                     : search === "" && status !== "" && availability !== "" && type !== "" && subType !== "" && emphasis === false ? availabilityStatusFilterTypeSubtype 
                     : search === "" && status !== "" && availability === "" && type !== "" && subType !== "" && emphasis === false ? statusFilterTypeSubtype 
                     : search === "" && status === "" && availability !== "" && type !== "" && subType !== "" && emphasis === false ? availabilityFilterTypeSubtype 
                     : search === "" && status !== "" && availability === "" && type !== "" && subType === "" && emphasis === false ? statusFilterType 
                     : search === "" && status === "" && availability !== "" && type !== "" && subType === "" && emphasis === false ? availabilityFilterType 
                     : search === "" && status === "" && availability === "" && type !== "" && subType === "" && emphasis === false ? typeFilter 
                     : search === "" && status === "" && availability === "" && type !== "" && subType !== "" && emphasis === false ? subTypeFilter 
                     : search === "" && status === "" && availability === "" && type === "" && subType === "" && emphasis === true ? emphasisFilter 
                     : autosSelecteds


                     function handleNewPropertyIntegration(id) {
                        console.log(id);
                        const auto = autosSelecteds.find(auto => id === auto.id);
                
                        const data = {
                            id:auto.id,
                            idIntegration: auto.idIntegration,
                            idCompany: auto.idCompany,
                            avatarCompany: auto.avatarCompany,
                            nameCompany: auto.nameCompany,
                            characteristcs: auto.characteristcs,
                            informations: auto.informations,
                            description: auto.description,
                            type: auto.type,
                            plate: auto.plate,
                            chassi: auto.chassi,
                            brand: auto.brand,
                            model: auto.model,
                            version: auto.version,
                            segment: auto.segment,
                            subsegment: auto.subsegment,
                            doors: auto.doors,
                            color: auto.color,
                            year: auto.year,
                            yearModel: auto.yearModel,
                            mileage: auto.mileage,
                            march: auto.march,
                            engineCapacity: auto.engineCapacity,
                            direction: auto.direction,
                            fuel: auto.fuel,
                            endOfBoard: auto.endOfBoard,
                            value: auto.value,
                            valueFipe: auto.valueFipe,
                            status: auto.status,
                            state: auto.state, 
                            financing: auto.financing,
                            city: auto.city,
                            uf: auto.uf,
                            cityCompany: user.city,
                            ufCompany: user.uf,
                            horses: auto.horses,
                            bodywork: auto.bodywork,
                            video: auto.video,
                            platformVideo: auto.platformVideo,
                            images: auto.images,
                            featuredImage: auto.featuredImage,
                            emphasis: auto.emphasis,
                            licensingInfos: auto.licensingInfos,
                            availability: auto.availability,
                            eletricCar: auto.eletricCar,
                            gnv: auto.gnv,
                            phone: user.whatsapp
                        }
                
                       newAuto(data)
                    }
                    function handleDeletePropertyIntegration(data) {
                        console.log(data);
                        const property = autosSelecteds.find(property => data === property.id);
                        console.log(property);
                    }
                

    return (
        <div className="MyAutos">
            <NavbarAdm />
            <ToolBar />
            <div className="aside">
                <div className="textHome">
                <h3>Meus autos</h3>

                <h4>{data?.length}
                {plains?.namePlain === "Free" ? "/5 " :
                    plains?.namePlain === "Start" ? "/100 " :
                    plains?.namePlain === "Lite" ? "/200 " :
                    plains?.namePlain === "Pro" ? " " :

                    "" 
                 }
                  autos publicados
                 </h4>

                <h3><a className="link" href={plains?.namePlain === "Free" && data?.length >= 5 ? "/atualizar-plano/Agent" :
                    plains?.namePlain === "Start" && data?.length >= 100 ? "/atualizar-plano/Agency" :
                    plains?.namePlain === "Agent" && data?.length >= 100 ? "/atualizar-plano/Agency" :
                    plains?.namePlain === "Lite" && data?.length >= 200 ? "/atualizar-plano/Business" :
                    "/novoauto"
                 }>+ Novo anúncio</a></h3>
                </div>
              

                <div className="search">
                    <input type="text" placeholder="Busque: ID, Marca, Modelo, Versão, Placa ou Ano" value={search} onChange={e => setSearch(e.target.value)} onClick={handleClear}/>
                    <div className="selection">
                    <select value={type} onChange={handleType}>
                        <option value="">Tipo</option>
                        <option value="Carros">Carros</option>
                        <option value="Motos">Motos</option>
                    </select>
                    <select value={availability} onChange={handleAvailability}>
                        <option value="">Visualização</option>
                        <option value="Disponível">Disponível</option>
                        <option value="Vendido">Vendido</option>
                        <option value="Reservado">Reservado</option>
                        <option value="Indisponível">Indisponível</option>
                    </select>
                    <select value={emphasis} onChange={handleEmphasis}>
                        <option value={false}>Sem destaque</option>
                        <option value={true}>Com destaque</option>
                    </select>
                    </div>
                </div>

                <div className="search">
                    <button onClick={() => selectLists("")} className={filter !== "" ? "other" : ""}>Autos cadastrados <span>{data?.length}</span></button>
                    <button onClick={() => selectLists("New")} className={filter === "New" ? "newSelected" : "new"}>Autos da integração <span>{myIntegrationProperty?.length}</span></button>
                    {/* <button onClick={() => selectLists("Old")} className={filter === "Old" ? "deleteSelected" :"delete"}>Autos Só aqui <span>{OlsIntegrations?.length}</span></button> */}
                </div>


            <div className="informations">

                {filterData?.map((auto) => {
                    return (
                        <div className="AutoListAdm" key={auto.id}>
                        <div className="image">
                            <a href="/conversa">
                            <img src={auto?.featuredImage} alt="" />
                            </a>
                        </div>
                        <div className="textAutoListAdm">
                            <div className="textDataAutoListAdm">
                        <h4>ID: {auto?.id} - ID INTEGRAÇÃO:{auto?.idIntegration} | {auto?.brand} - {auto?.model}</h4>
                        <h5><IoCarOutline />{auto?.version} - {auto?.year}/{auto?.yearModel} - Placa: {auto?.plate}</h5>
                        {filter === "New" ?
                        ""
                        : filter === "Old" ?   
                        ""
                        :
                        <>
                        <h5><IoLocationOutline />{auto?.city} - {auto?.uf} | {auto?.fuel}</h5>
                        <h6>Cadastrado em <DateFormat2 date={auto?.created_at} /></h6>
                    </>
                }
                            </div>
                            {filter === "New" ?
                        ""
                        : filter === "Old" ?   
                       ""
                    :
                    <>
                            <div className="AutoView">
                            <h4 className="emphasis">Destaque: {auto?.emphasis === false ?  "Não ": "Sim"}</h4>
                            <h4 className={
                                auto?.availability === "Disponível" ? "status1" :
                                auto?.availability === "Vendido" ? "status2" :
                                auto?.availability === "Alugado" ? "status3" : "status4"
                            }>{auto?.availability}</h4>

                                


                            </div>
                        <div className="infosContactData">
                            <div className="infoUnicData">
                            <IoEyeOutline />
                                <h6> <CountersViews id={auto?.id}/> Visualizações</h6>
                            </div>
                            <div className="infoUnicData">
                            <IoHeartOutline />
                                <h6> <CountersFavorites id={auto?.id}/> Salvos</h6>
                            </div>
                            <div className="infoUnicData">
                            <IoLogoWhatsapp />
                                <h6> <CountersWhatsapp id={auto?.id}/> Whatsapp</h6>
                            </div>
                            <div className="infoUnicData">
                            <IoCallOutline />
                                <h6><CountersContact id={auto?.id}/> Ligações</h6>
                            </div>

                             {/* <MatchAuto id={auto?.id}/> */}

                            {/* <MatchAutoSearch  status={auto?.status} type={auto?.type} subType={auto?.subType}
                             uf={auto?.uf} city={auto?.city} district={auto?.district} 
                             bedroom={auto?.bedroom} restroom={auto?.restroom} garage={auto?.garage}
                             suite={auto?.suite} pets={auto?.pets} furnished={auto?.furnished}/> */}

                        
                        </div>
                    </>
                }
                </div>
               
                {filter === "New" ?
                        <div className="Action">
                            <button className="btnNew" onClick={() => handleNewPropertyIntegration(auto?.id)}>Adicionar auto</button>
                        </div>
                        : filter === "Old" ?   
                        <div className="Action">
                         <button className="btnDelete" onClick={() => handleDeletePropertyIntegration(auto?.id)}>Remover auto</button>
                    </div>
                    :
                        <div className="buttons">

                        <ViewAutoList id={auto?.id}/>

                        <a href={`/editar-auto/${auto?.id}`} className="btnControl" data-tip data-for='Editar'><IoCreateOutline /></a>
    
                        {plains?.namePlain === "Free" ?
                         <a href={"/atualizar-plano/Start"} className="btnControl" data-tip data-for='Editar'><IoTrashOutline /></a>
                          :
                        <DeleteAuto id={auto?.id} brand={`${auto?.brand}`} model={`${auto?.model}`} version={`${auto?.version}`}
                                        plate={`${auto?.plate}`} year={`${auto?.year}/${auto?.yearModel}`}
                                            address={`${auto?.city} - ${auto?.uf}`}
                                            />
                }
    
  
                        {/* <NewNegotiations idAuto={auto.id}/> */}
                        <EditStatusAuto id={auto?.id}/>
                        </div>
                }
                    </div>  
                    )
                })}


            </div>
            </div>



        </div>
    )
}